import React from "react";
import ReactSelect, {
  OptionTypeBase,
  Props as ReactSelectProps,
} from "react-select";

import { css } from "@styled-system/css";

import { textVariants } from "../Text/textVariants";

export type SelectProps = ReactSelectProps & {
  placeholder?: string;
  onChange?: (_newValues: OptionTypeBase[]) => void;
};

export const Select = ({
  placeholder = "Please select an option",
  ...props
}: SelectProps) => (
  <ReactSelect
    isMulti
    placeholder={placeholder}
    css={css({
      "width": ["100%", "70%", 300],
      ...textVariants.bodySmall,

      "& > div": {
        borderRadius: "lg",
      },
      "& > div:nth-child(2) > div:nth-child(1)": {
        pl: "sm",
      },
      "& > div:nth-child(3) > *": {
        borderRadius: "lg",
      },
    })}
    {...props}
  />
);
