import React, { CSSProperties } from "react";
import styled from "styled-components";
import {
  space,
  color,
  opacity,
  typography,
  SpaceProps,
  OpacityProps,
  TextShadowProps,
  TypographyProps,
  ColorProps,
  textShadow,
  system,
  compose,
  PaddingProps,
} from "styled-system";
import { Theme } from "../theme";
import { textVariants, TextVariant } from "./textVariants";

export type TextProps = Omit<SpaceProps<Theme>, keyof PaddingProps<Theme>> &
  ColorProps<Theme> &
  Omit<
    TypographyProps<Theme>,
    | "fontSize"
    | "fontWeight"
    | "fontFamily"
    | "fontStyle"
    | "letterSpacing"
    | "lineHeight"
    | "textShadow"
  > & {
    fontSize?: number | number[];
    fontWeight?: number;
    overrideColor?: string;
    textWrap?: "wrap" | "nowrap" | "pretty" | "balance";
  } & TextShadowProps<Theme> &
  OpacityProps<Theme> & {
    variant: keyof typeof textVariants;
    maxLines?: number;
    textDecoration?: CSSProperties["textDecoration"];
    textTransform?: CSSProperties["textTransform"];
    onClick?: () => void;
    asElement?: "h1" | "h2" | "h3" | "h4" | "p" | "span";
  };

const _Text = styled.p<TextProps>(
  compose(
    space,
    color,
    typography,
    textShadow,
    opacity,
    system({
      marginBlockEnd: {
        property: "marginBlockEnd",
        scale: "space",
      },
      marginBlockStart: {
        property: "marginBlockStart",
        scale: "space",
      },
      marginBlock: {
        properties: ["marginBlock"],
        scale: "space",
      },
    }),
  ),
);

const InnerText: React.FC<TextProps> = ({
  variant,
  maxLines,
  textDecoration = "none",
  textTransform = "none",
  fontSize,
  fontWeight,
  color,
  overrideColor,
  asElement,
  ...props
}) => {
  const variantProps = {
    ...textVariants[variant],
    fontSize: fontSize ?? textVariants[variant].fontSize,
    fontWeight: fontWeight ?? textVariants[variant].fontWeight,
    color: overrideColor || (color ?? textVariants[variant].color),
  };
  const overflowProps = maxLines
    ? {
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "display": "-webkit-box",
        "-webkit-line-clamp": `${maxLines}`,
        "-webkit-box-orient": "vertical",
      }
    : {};
  return (
    <_Text
      as={asElement ?? (variantProps.htmlElement as any)}
      style={{
        ...overflowProps,
        textDecoration,
        textTransform,
      }}
      pt={getPaddingTop({
        fontSize: variantProps.fontSize,
        fontFamily: variantProps.fontFamily,
      })}
      {...variantProps}
      {...props}
    />
  );
};

export const Text: React.FC<TextProps> = React.memo(InnerText);

const getPaddingTop = ({
  fontSize,
  fontFamily,
}: {
  fontSize: TextVariant["fontSize"];
  fontFamily: TextVariant["fontFamily"];
}) => {
  const calcFn = paddingTopErrorCalculators[fontFamily];
  if (!calcFn) return 0;

  return Array.isArray(fontSize) ? fontSize.map(calcFn) : calcFn(fontSize);
};

const paddingTopErrorCalculators: Partial<
  Record<TextVariant["fontFamily"], (_fontSize?: number) => number>
> = {
  Teko: (fontSize) => (!fontSize ? null : (fontSize * 3) / 20),
};
