import { CancellationSurveyAnalyticEvents } from "./cancellationSurveyAnalytics";

export const SegmentEvents = {
  WebSignupStarted: "signup_started",
  AuthSignInStarted: "login_started",
  WebSignupVisit: "web_signup_loaded",
  WebSignupHOTNVisit: "web_signup_hotn_loaded",
  WebSignupAccountAlreadyExist: "error_account_already_exist",
  AccountDeleted: "account_deleted",
  LogoutComplete: "logout_completed",
  PaywallViewed: "paywall_viewed",
  MasterclassViewed: "masterclass_viewed",
  MasterclassStarted: "masterclass_started",
  MasterclassCompleted: "masterclass_completed",
  MeditationViewed: "meditation_viewed",
  MeditationStarted: "meditation_started",
  MeditationCompleted: "meditation_completed",
  RecipeViewed: "recipe_viewed",
  RecipeLongViewed: "recipe_long_viewed",
  RecipeSearched: "recipe_searched",
  BumpMedicalCheckShown: "bump_medical_check_shown",
  BumpMedicalCheckDismissed: "bump_medical_check_dismissed",
  BumpMedicalCheckCompleted: "bump_medical_check_completed",
  BumpEdmSubscribed: "bump_edm_subscribed",
  WebAppLoaded: "web_app_loaded",
  ROKTPaymentCompleted: "rokt_payment_completed",
  ROKTAccountCreated: "rokt_account_created",
  PartnerSignupCompleted: "partner_signup_completed",
  AppDownloadViewed: "app_download_viewed",
  AppDownloadClicked: "app_download_clicked",
  PlanSelected: "plan_selected",
  ...CancellationSurveyAnalyticEvents,
};
