import React from "react";

import { Box, Text, ColorThemeTokens } from "../../../../ui";

import { Discount, Plan } from "../../../../api";
import { PlanPrice } from "../PlanPrice/PlanPrice";

interface PlanItemProps {
  plan: Plan;
  discount?: Discount;
  saleHeader?: string;
  showDescription?: boolean;
  isSelected: boolean;
  onPress: (_plan: Plan) => void;
}

const InnerPlanItem: React.FC<PlanItemProps> = ({
  plan,
  discount,
  saleHeader,
  isSelected,
  showDescription = true,
  onPress,
  ...props
}) => {
  const isSaleActive = !!discount;

  const colors: Record<string, ColorThemeTokens> = {
    selectedBackgroundColor: isSaleActive ? "backgroundTeal" : "backgroundTeal",
    salesHeaderColor: isSaleActive ? "contentEmber" : "contentTeal",
    salesHeaderBorder: isSaleActive ? "borderLight" : "contentTeal",
    priceSelectedColor: isSaleActive ? "contentTeal" : "contentContrastHigh",
    priceColor: isSaleActive ? "contentTeal" : "contentContrastMid",
  };

  const saleText =
    discount &&
    (saleHeader ??
      `Save ${discount.unit === "flat" ? "$" : ""}${discount.amount}${
        discount.unit === "percentage" ? "%" : ""
      }`);
  const descriptionText = showDescription ? plan.description : "";

  const displayText = isSaleActive ? saleText : descriptionText;

  return (
    <Box
      borderRadius="md"
      borderWidth="sm"
      display="flex"
      borderColor={isSelected ? colors.selectedBackgroundColor : "borderLight"}
      borderStyle="solid"
      flexDirection="column"
      alignContent="center"
      padding={["xs", "sm"]}
      backgroundColor={
        isSelected ? colors.selectedBackgroundColor : "backgroundPrimary"
      }
      onClick={() => onPress(plan)}
      cursor="pointer"
      aria-selected={isSelected}
      {...props}
    >
      <Box
        padding="xs"
        flexDirection="row"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
      >
        <Box flexDirection="column">
          <Box flexDirection="row" alignContent="center" alignItems="center">
            <Text
              variant="buttonMedium"
              color="contentContrastHigh"
              marginRight="sm"
              textTransform="uppercase"
            >
              {plan.name}
            </Text>
            {displayText && (
              <Box
                bg="white"
                borderRadius="full"
                borderStyle="solid"
                borderWidth="sm"
                borderColor={colors.salesHeaderBorder}
                px="sm"
                py="xs"
                mr={["xs", "sm", "md"]}
                display={["none", "flex", "flex"]}
              >
                <Text
                  variant="imageTag"
                  color={colors.salesHeaderColor}
                  textTransform="uppercase"
                >
                  {displayText}
                </Text>
              </Box>
            )}
          </Box>
        </Box>

        <PlanPrice
          plan={plan}
          discount={discount}
          variant="h4"
          fontSize={[24, 32, 40]}
          color={isSelected ? colors.priceSelectedColor : colors.priceColor}
        />
      </Box>
    </Box>
  );
};

export const PlanItem = React.memo(InnerPlanItem);
