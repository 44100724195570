import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  StripeCardNumberElement,
  StripeExpressCheckoutElementConfirmEvent,
} from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  ExpressCheckoutElement,
} from "@stripe/react-stripe-js";
import { createGlobalStyle } from "styled-components";

import { Box, Divider, Text } from "../../../ui";
import {
  makeBaseInputStyle,
  makeErrorInputStyle,
} from "../../../ui/InputText/BaseInputText";
import { usePayments } from "../../Payments/components/PaymentContext";

const GlobalStyle = createGlobalStyle`
  .StripeCardElement {
    ${makeBaseInputStyle};
  }
  .StripeElement--invalid {
    ${makeErrorInputStyle};
  }

  .StripeExpressElement { }
`;

const clearElement = (element) => {
  if (!!element && !element._destroyed) element.clear();
};

export type SubmitPaymentCallback = ({ }: {
  cardElement?: StripeCardNumberElement;
}) => Promise<{ isSuccessful: boolean; error?: unknown }>;

type PaymentElementsProps = {
  onSubmit: SubmitPaymentCallback;
  enableWallets?: boolean;
};

const _PaymentElements: React.ForwardRefRenderFunction<
  { onSubmitForm: () => void },
  PaymentElementsProps
> = ({ onSubmit, enableWallets = false }, ref) => {
  const { elements, stripe } = usePayments();
  const [expressElementsAvailable, setExpressElementsAvailable] =
    useState(false);

  useImperativeHandle(ref, () => ({
    onSubmitForm,
  }));

  const onSubmitForm = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    const { isSuccessful } = await onSubmit({ cardElement: cardNumberElement });

    if (isSuccessful) {
      clearElement(cardNumberElement);
      clearElement(cardExpiryElement);
      clearElement(cardCvcElement);
    }
  };

  const onSubmitWallet = async (
    event: StripeExpressCheckoutElementConfirmEvent,
  ) => {
    if (!stripe || !elements) return;

    const { isSuccessful } = await onSubmit({});
    if (!isSuccessful) event.paymentFailed();
  };

  return (
    <Box onSubmit={onSubmitForm} data-cy="card-details-form">
      <GlobalStyle />
      {enableWallets && (
        <Box mb={expressElementsAvailable ? "md" : "none"}>
          <ExpressCheckoutElement
            onConfirm={onSubmitWallet}
            onReady={({ availablePaymentMethods }) =>
              setExpressElementsAvailable(
                Object.values(availablePaymentMethods || {}).some(Boolean),
              )
            }
            options={{
              buttonType: { applePay: "subscribe", googlePay: "subscribe" },
              layout: { maxColumns: 1, maxRows: 2 },
              buttonTheme: { applePay: "black", googlePay: "black" },
            }}
            className="StripeExpressElement"
          />

          {expressElementsAvailable && (
            <>
              <Box flexDirection="row" alignItems="center">
                <Divider />
                <Text variant="labelSmall" mx="xs">
                  or
                </Text>
                <Divider />
              </Box>
              <Text variant="labelSmall" textAlign="center">
                Continue with Credit or Debit
              </Text>
            </>
          )}
        </Box>
      )}
      <Box mb="sm">
        <CardNumberElement
          className="StripeCardElement"
          options={{ showIcon: true, iconStyle: "solid" }}
        />
      </Box>
      <Box flexDirection="row" alignItems="center">
        <Box mr="sm" flex={1}>
          <CardExpiryElement className="StripeCardElement" />
        </Box>
        <Box ml="sm" flex={1}>
          <CardCvcElement className="StripeCardElement" />
        </Box>
      </Box>
    </Box>
  );
};

export const PaymentElements = forwardRef(_PaymentElements);
