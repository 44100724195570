import { LogLevel, Purchases } from "@revenuecat/purchases-js";
import { RevenueCatUserId, SubscriptionInfo } from "../types";
import { getEnvConfig } from "../../../utils/appConfig/envConfig";

type Listener = (_subscriptionInfo: SubscriptionInfo) => void;

const createRevenueCatClient = () => {
  let revenueCatInstance: Purchases = null;
  const listeners: Listener[] = [];

  const dispatch = (subscriptionInfo: SubscriptionInfo) => {
    listeners.forEach((listener) => listener(subscriptionInfo));
  };

  const clearInstance = (notifyListeners: boolean) => {
    if (revenueCatInstance) {
      revenueCatInstance.close();
      revenueCatInstance = null;
      if (notifyListeners) dispatch(undefined);
    }
  };

  return {
    sync: async (revenueCatUserId: RevenueCatUserId, resetCache: boolean) => {
      if (!revenueCatUserId) {
        clearInstance(true);
      } else {
        if (resetCache) {
          clearInstance(false);
        }

        const { revenueCat, appStage } = getEnvConfig();

        if (!revenueCatInstance) {
          await Purchases.setLogLevel(
            appStage === "production" ? LogLevel.Error : LogLevel.Debug,
          );
          revenueCatInstance = Purchases.configure(
            revenueCat.apiKey,
            revenueCatUserId,
          );
        }
        await revenueCatInstance?.changeUser(revenueCatUserId);

        // we would set details here, but the library currently does not support it
        //Purchases.setDisplayName(`${user.first_name} ${user.last_name}`);
        //if (user.email) await Purchases.setEmail(user.email);

        const customerInfo = await revenueCatInstance?.getCustomerInfo();

        dispatch({
          revenueCatUserId,
          customerInfo,
        });

        return customerInfo;
      }
    },

    // listeners are just so we can refetch from other parts of code
    // it will not auto update if a user pays on a different platform
    // or something
    addListener: (listener: Listener) => {
      listeners.push(listener);
    },
    removeListener: (listener: Listener) => {
      const index = listeners.findIndex((l) => l === listener);
      if (index < 0) throw new Error("listener not found to remove");
      listeners.splice(index, 1);
    },
  };
};

export const revenueCatClient = createRevenueCatClient();
