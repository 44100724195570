import { Plan } from "../../../api";

const partnerPrefixes = {
  nib: "NIB_",
  mecca: "MECCA_",
  frankgreen: "FRANK",
  rebel: "REBEL",
} as const;

export const partnerPrefixesValues = Object.values(partnerPrefixes);

export type PartnerPrefixes =
  (typeof partnerPrefixes)[keyof typeof partnerPrefixes];

type PartnerMap = {
  // eslint-disable-next-line no-unused-vars
  [Partner in keyof typeof partnerPrefixes]: {
    prefix: PartnerPrefixes;
    landingPage: {
      title: string;
    };
    planSelectionPage: {
      title: string;
      subtitle: string;
      planSubtitle: Record<Plan["name"], string>;
    };
    defaultPlanName: Plan["name"];
    availablePlans: Array<Plan["name"]>;
  };
};

const partnerMap: PartnerMap = {
  rebel: {
    prefix: partnerPrefixes.rebel,
    landingPage: {
      title: "Unlock your free month with Kic",
    },
    planSelectionPage: {
      title: "Select the monthly plan & enter your promo code",
      subtitle: "No charge for 30 days, cancel anytime",
      planSubtitle: {
        quarterly: "No charge for 30 days",
      },
    },
    defaultPlanName: "monthly",
    availablePlans: ["monthly"],
  },
  frankgreen: {
    prefix: partnerPrefixes.frankgreen,
    landingPage: {
      title: "Unlock your free month with Kic",
    },
    planSelectionPage: {
      title: "Select the monthly plan & enter your promo code",
      subtitle: "No charge for 30 days, cancel anytime",
      planSubtitle: {
        quarterly: "No charge for 30 days",
      },
    },
    defaultPlanName: "monthly",
    availablePlans: ["monthly"],
  },
  nib: {
    prefix: partnerPrefixes.nib,
    landingPage: {
      title: "Unlock your three months free with Kic",
    },
    planSelectionPage: {
      title: "Select the quarterly plan & enter your promo code",
      subtitle: "No charge for 90 days, cancel anytime",
      planSubtitle: {
        quarterly: "No charge for 90 days",
      },
    },
    defaultPlanName: "quarterly",
    availablePlans: ["quarterly"],
  },
  mecca: {
    prefix: partnerPrefixes.mecca,
    landingPage: {
      title: "Unlock your three months free with Kic",
    },
    planSelectionPage: {
      title: "Select the quarterly plan & enter your promo code",
      subtitle: "No charge for 90 days, cancel anytime",
      planSubtitle: {
        quarterly: "No charge for 90 days",
      },
    },
    defaultPlanName: "quarterly",
    availablePlans: ["quarterly"],
  },
};

export type Partner = keyof typeof partnerMap;

export const useGetPartnerDetails = (
  partner: string,
): PartnerMap[Partner] | null => {
  return partnerMap[partner] || null;
};
