import React from "react";
import styled from "styled-components";
import {
  space,
  color,
  typography,
  textShadow,
  layout,
  background,
  border,
  SpaceProps,
  ColorProps,
  TypographyProps,
  TextShadowProps,
  LayoutProps,
  BackgroundProps,
  BorderProps,
  compose,
} from "styled-system";
import { css } from "@styled-system/css";

import { Theme } from "../theme";

import { Box, BoxProps } from "../Box";
import { Text } from "../Text";

export interface CheckboxProps extends BoxProps {
  label?: string;
  name?: string;
  checked?: boolean;
  onChange: (_e) => void;
}

type ReactInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type _CheckboxProps = SpaceProps<Theme> &
  TypographyProps<Theme> &
  ColorProps<Theme> &
  TextShadowProps<Theme> &
  LayoutProps<Theme> &
  BackgroundProps<Theme> &
  BorderProps<Theme> &
  ReactInputProps;

const _Checkbox = styled.input<_CheckboxProps>(
  compose(space, color, typography, textShadow, layout, background, border),
  css({
    "appearance": "none",
    "backgroundColor": "transparent",
    "transform": "translateY(-0.5px)",
    "display": "grid",
    "placeContent": "center",
    "cursor": "pointer",
    "borderRadius": "50%",
    "flex-shrink": 0,

    "&::before": {
      content: "''",
      backgroundImage: "url('/static/images/check.svg')",
      display: "inline-flex",
      backgroundSize: "12px 12px",
      width: "12px",
      height: "12px",
      transform: "scale(0)",
      transition: "120ms transform ease-in-out",
    },

    "&:checked::before": {
      transform: "scale(1)",
    },

    "&:checked": {
      borderStyle: "none",
      backgroundColor: "contentContrastHigh",
    },
  }),
);

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  checked,
  onChange,
  children,
  ...props
}) => {
  if (label && children)
    throw new Error(
      `You can only use label or children, but not both! (name: ${name})`,
    );
  if (!label && !children)
    throw new Error(
      `You must have either a label or children! (name: ${name})`,
    );

  return (
    <Box flexDirection="row" alignItems="center" p="sm" {...props}>
      <_Checkbox
        borderColor="borderDark"
        borderWidth="sm"
        borderRadius="xs"
        borderStyle="solid"
        height={20}
        width={20}
        type="checkbox"
        id={name}
        name={name}
        aria-label={name}
        checked={checked}
        onChange={onChange}
        m="none"
        mr="sm"
      />

      <label htmlFor={name}>
        {label && <Text variant="bodySmall">{label}</Text>}
        {children}
      </label>
    </Box>
  );
};
