import React, { MouseEventHandler } from "react";

import * as animationData from "../../../../../public/static/lottie/spinner-white.json";
import { Container, StyledLottie, Text } from "./styles";

type CTAButtonProps = React.ComponentProps<"button"> & {
  text: string;
  care?: boolean;
  secondary?: boolean;
  onClick: (_event: MouseEventHandler) => void;
  isLoading?: boolean;
  buttonStyle?: any;
};

const CTAButton: React.FC<CTAButtonProps> = (props) => {
  const {
    text,
    onClick,
    secondary,
    isLoading,
    disabled,
    buttonStyle,
    care,
    type,
  } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: (animationData as any).default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container
      onClick={onClick}
      secondary={secondary}
      disabled={disabled || isLoading}
      style={buttonStyle}
      care={care}
      type={type}
    >
      {!isLoading ? (
        <Text secondary={secondary} care={care} disabled={disabled}>
          {text}
        </Text>
      ) : (
        <StyledLottie options={defaultOptions} height={40} width={40} />
      )}
    </Container>
  );
};

export default React.memo(CTAButton);
