import { useCallback, useMemo, useState } from "react";
import { useQuery, useQueries, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";

import { Discount, Plan } from "../../../api";
import { useCreateAPIClient, useUrlParam } from "../../../hooks";

import { shouldApplyAutoCoupon } from "../shouldApplyAutoCoupon";
import { useGetPartnerDetails } from "../../Onboarding/utils/useGetPartner";

export type ExtendedDiscount = Discount & {
  planId: string;
  code: string;
  saleHeader?: string;
};

export type ExtendedPlan = Plan & {
  discount?: ExtendedDiscount;
  discountCodes?: {
    sale?: string;
    manual?: string;
  };
};

/**
 * @TODO most of this logic should live on the API
 * See: https://www.notion.so/keepitcleaner/API-returns-Plans-with-discounts-applied-7e76a00b5ccf4cac9f8e9e912cf8f8b3
 */

export const usePlans = ({
  setDiscountError,
  userHasManuallyRemovedDiscount = false,
}: {
  setDiscountError?: (_message: string) => void;
  userHasManuallyRemovedDiscount?: boolean;
}) => {
  const apiClient = useCreateAPIClient();

  const [appliedDiscount, setAppliedDiscount] = useState<{
    planId: string;
    discountCode: string;
  }>(undefined);
  const partnerQueryParam = useUrlParam({
    paramName: "partner",
  });
  const partner = useGetPartnerDetails(partnerQueryParam);

  const { isLoading: plansLoading, data: fetchedPlans } = useQuery({
    queryKey: "plans.list",
    queryFn: () => apiClient.plans.list(),
    select: (data) => {
      return formatPlans(data, !!partner, userHasManuallyRemovedDiscount);
    },
  });

  const applyDiscount = useCallback(
    (planId: string, discountCode: string) =>
      setAppliedDiscount({ planId, discountCode }),
    [setAppliedDiscount],
  );

  const plans: Record<string, ExtendedPlan> = useMemo(
    () => ({
      ...fetchedPlans,
      ...(appliedDiscount
        ? {
            [appliedDiscount.planId]: {
              ...fetchedPlans[appliedDiscount.planId],
              discountCodes: {
                ...fetchedPlans[appliedDiscount.planId].discountCodes,
                manual: appliedDiscount.discountCode,
              },
            },
          }
        : {}),
    }),
    [fetchedPlans, appliedDiscount],
  );

  const discountQueries = useQueries(
    Object.values(plans).map(
      ({
        _id: planId,
        discountCodes,
      }): UseQueryOptions<ExtendedPlan["discount"], Error> => {
        const hasManualDiscountCode = !!discountCodes?.manual;

        const discountCode = hasManualDiscountCode
          ? discountCodes?.manual
          : discountCodes?.sale;
        return {
          queryKey: ["coupon.verify", planId, discountCode],
          queryFn: () =>
            apiClient.coupon
              .verify({
                discountCode,
                planId,
              })
              .then((discount) => ({
                ...discount,
                code: discountCode,
                planId,
              }))
              .catch((err: AxiosError<{ error: { message?: string } }>) => {
                throw Error(err?.response?.data?.error?.message);
              }),
          enabled: !!discountCode,
          onError: (err) =>
            hasManualDiscountCode && setDiscountError?.(err.message),
        };
      },
    ),
  );

  const plansWithDiscounts = useMemo(
    () =>
      Object.values(plans).map((plan) => {
        const { data: discount } =
          discountQueries.find((q) => q?.data?.planId === plan._id) ?? {};
        const isDiscountSale = discount?.code === plan?.auto_coupon?.coupon;

        if (!discount) return plan;
        return {
          ...plan,
          trial_duration: 0, // @HACK: but the API will match it on createSub anyway :shrug:
          discount: {
            ...discount,
            saleHeader: isDiscountSale
              ? plan.auto_coupon.saleHeader
              : discount.saleHeader,
          },
        };
      }),
    [plans, discountQueries],
  );

  return {
    plansLoading,
    applyDiscount,
    plans: plansWithDiscounts,
    discountsLoading: discountQueries.some((q) => q.isLoading),
  };
};

const formatPlans = (
  plans: Plan[],
  isPartnerQueryParam = false,
  userHasManuallyRemovedDiscount = false,
) => {
  return plans
    .map(
      (plan): ExtendedPlan => ({
        ...plan,
        discountCodes: {
          sale:
            !userHasManuallyRemovedDiscount &&
            shouldApplyAutoCoupon(plan.auto_coupon) &&
            !isPartnerQueryParam
              ? plan.auto_coupon.coupon
              : null,
        },
      }),
    )
    .reverse()
    .reduce(
      (obj, p) => ({
        ...obj,
        [p._id]: p,
      }),
      {},
    );
};
