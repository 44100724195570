import ReactLottie from "react-lottie";
import styled from "styled-components";

type ContainerProps = {
  secondary?: boolean;
  theme?: {
    adminCTAButtonSecondaryColour?: string;
    adminCTAButtonPrimaryColour?: string;
    adminCTAButtonCareColour?: string;
    adminCTAButtonCareBorderColour?: string;
    adminCTAButtonCareShadowColour?: string;
    adminCTAButtonSecondaryTextColour?: string;
    adminCTAButtonPrimaryTextColour?: string;
    adminCTAButtonHoverPrimaryColour?: string;
    adminCTAButtonSecondaryTextShadow?: string;
    adminCTAButtonCareTextShadow?: string;
    adminCTAButtonHoverSecondaryColour?: string;
    adminCTAButtonHoverCareColour?: string;
    adminCTAButtonPrimaryTextShadow?: string;
    adminCTAButtonSecondaryBorderColour?: string;
    adminCTAButtonPrimaryBorderColour?: string;
    CTAButtonBorderRadius?: string;
    adminCTAButtonSecondaryShadowColour?: string;
    adminCTAButtonPrimaryShadowColour?: string;
  };
  care?: boolean;
  disabled?: boolean;
};

export const Container = styled.button<ContainerProps>`
  align-items: center;
  background: ${(props) =>
    props.secondary
      ? props.theme.adminCTAButtonSecondaryColour
      : props.theme.adminCTAButtonPrimaryColour};
  border-color: ${(props) =>
    props.secondary
      ? props.theme.adminCTAButtonSecondaryBorderColour
      : props.theme.adminCTAButtonPrimaryBorderColour};
  border-radius: ${(props) => props.theme.CTAButtonBorderRadius};
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 0
    ${(props) =>
      props.secondary
        ? props.theme.adminCTAButtonSecondaryShadowColour
        : props.theme.adminCTAButtonPrimaryShadowColour};
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  padding: 0 1rem;
  outline: none;
  width: fit-content;

  ${(props) =>
    props.care &&
    `
    background: ${props.theme.adminCTAButtonCareColour};
    border-color: ${props.theme.adminCTAButtonCareBorderColour};
    box-shadow: 0 1px 0 ${props.theme.adminCTAButtonCareShadowColour};
  `};

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
  `};

  :hover {
    background: ${(props) =>
      props.secondary
        ? props.theme.adminCTAButtonHoverSecondaryColour
        : props.theme.adminCTAButtonHoverPrimaryColour};

    ${(props) =>
      props.care &&
      `
      background: ${props.theme.adminCTAButtonHoverCareColour};
    `}
  }
`;

export const Text = styled.p<ContainerProps>`
  color: ${(props) =>
    props.secondary
      ? props.theme.adminCTAButtonSecondaryTextColour
      : props.theme.adminCTAButtonPrimaryTextColour};
  font-size: 1.2rem;
  font-weight: 800;
  margin: 0;
  text-shadow: ${(props) =>
    props.secondary
      ? props.theme.adminCTAButtonSecondaryTextShadow
      : props.theme.adminCTAButtonPrimaryTextShadow};
  text-transform: uppercase;

  ${(props) =>
    props.disabled &&
    `
    color: #66c6e4;
  `};

  ${(props) =>
    props.disabled &&
    props.care &&
    `
      color: #d87676;
  `};

  ${(props) =>
    props.disabled &&
    props.secondary &&
    `
      color: #d0d0d0;
  `};

  ${(props) =>
    props.care &&
    `
    text-shadow: ${props.theme.adminCTAButtonCareTextShadow};
  `};
`;

export const StyledLottie = styled(ReactLottie)`
  & {
    border: 1px solid;
    height: 5rem;
    width: 5rem;
  }
`;
