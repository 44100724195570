import axios from "axios";
/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import { action, thunk } from "easy-peasy";

import { KIC_API } from "../../../src/api/index";
import { logAxiosError } from "../../../src/utils/utils";
import initialState from "./admin.media.state";

const actions = {
  addToFrontOfMediaLibrary: action((state, payload) => {
    state.mediaLibrary.unshift(payload);
  }),
  addToMediaLibrary: action((state, payload) => {
    state.mediaLibrary = state.mediaLibrary.concat(payload);
  }),
  clearState: action((state) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = value;
    }
  }),
  fetchMedia: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { pageNumber, limit, search, type, next, sortField, sortOrder } =
          payload;
        const pageQuery = `?page=${pageNumber || 1}`;
        const limitQuery = limit ? `&limit=${limit}` : "";
        const searchQuery = search ? `&search=${search}` : "";
        const typeQuery = type ? `&type=${type}` : "";
        const sortFieldQuery = sortField ? `&sortField=${sortField}` : "";
        const sortOrderQuery = sortOrder ? `&sortOrder=${sortOrder}` : "";
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v1/media${pageQuery}${limitQuery}${searchQuery}${typeQuery}${sortFieldQuery}${sortOrderQuery}`,
          { headers },
        );

        if (next && !search) {
          actions.addToMediaLibrary(response.data.data);
          actions.setMediaLibraryPageSettings(response.data.settings);
        } else {
          actions.setMediaLibrary(response.data.data);
          actions.setMediaLibraryPageSettings(response.data.settings);
        }

        return response.data;
      } catch (error) {
        logAxiosError(error, "fetchMedia");
        return error.response.data;
      }
    },
  ),
  removeMedia: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        await axios.delete(`${KIC_API}/api/v1/media/${payload}`, { headers });

        actions.removedMedia(payload);
      } catch (error) {
        logAxiosError(error, "removeMedia");
      }
    },
  ),
  removedMedia: action((state, payload) => {
    state.mediaLibrary = state.mediaLibrary.filter(
      (item) => item._id !== payload,
    );
  }),
  setMediaLibrary: action((state, payload) => {
    state.mediaLibrary = payload;
  }),
  setMediaLibraryPageSettings: action((state, payload) => {
    state.mediaLibraryPageSettings = payload;
  }),
  setSearchLibrary: action((state, payload) => {
    state.searchLibrary = payload;
  }),
  updateMedia: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;
      try {
        const { id, ...rest } = payload;
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v1/media/${id}`,
          rest,
          { headers },
        );

        actions.updatedMedia(payload);
        return response.data;
      } catch (error) {
        logAxiosError(error, "updateMedia");
        return error.response.data;
      }
    },
  ),
  updatedMedia: action((state, payload) => {
    const { id, title, alt_text: altText } = payload;
    const index = state.mediaLibrary.findIndex((media) => media._id === id);
    state.mediaLibrary[index].title = title;
    state.mediaLibrary[index].alt_text = altText;
  }),
  uploadMedia: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { file } = payload;
        const formData = new FormData();

        formData.append("file", file);

        const headers = {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };

        const response = await axios.post(
          `${KIC_API}/api/v1/media/admin/upload`,
          formData,
          { headers },
        );

        return response.data;
      } catch (error) {
        logAxiosError(error, "uploadMedia");
        return error.response.data;
      }
    },
  ),
};

export default actions;
